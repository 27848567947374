<template>
    <!-- ======= Footer ======= -->
    <div>
        <footer class="main-footer pl-0 size-website">
            <div class="float-right d-none d-sm-block"><b>Phiên bản</b> 1.0.0</div>
            <strong>Bản quyền &copy; 2022 <a href="https://deepscore.ai" target="_blank">Deepscore</a>.</strong>
            <strong class="float-left pr-4 pl-4" data-widget="pushmenu" style="cursor: pointer;"
                v-on:click="checkShowMenu">
                <i v-if="showMenu" class="fas fa-chevron-left"></i>
                <i v-else class="fas fa-chevron-right"></i>
            </strong>
        </footer>
        <footer class="main-footer size-mobile">
            <!-- <div class="float-right d-none d-sm-block"><b>Phiên bản</b> 1.0.0</div> -->
            <strong>Bản quyền &copy; 2022 <a href="https://deepscore.ai" target="_blank">Deepscore</a>.</strong>
        </footer>
    </div>
    <!-- End Footer -->
</template>
<script>
export default {
    data: function () {
        return {
            showMenu: false
        };
    },
    methods: {
        checkShowMenu: function () {
            const sidebar = document.getElementsByClassName("sidebar-collapse");
            this.showMenu = sidebar.length > 0;
        }
    }
};
</script>
<style>
.main-footer a {
    color: #18a2b8 !important;
}
</style>